import { Field, Label, Table as AosTable } from '@aos/styleguide-react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import NavigationRoutes from '../../routing/NavigationRoutes';
import ActionBar from '../../ui/action-bar/ActionBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import { useTranslationText } from '@/translation/TranslationHooks';
import { useGetAllContractors } from '@/react-query/ContractManagementSystemApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import {
  ContractorQuery,
  ContractorQueryParamConfigMap,
  ContractorSort,
} from '@/models/contract-management/ContractorQuery';
import { DebouncedSearchBar } from '@/ui/filter/search-bar/SearchBar';
import Pagination from '../../ui/pagination/Pagination';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { getNextSortState } from '@/ui/table-sort/TableSort';
import NavigateButton from '../../generic-components/navigate-button/NavigateButton';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

const tableColumns: TableColumn[] = [
  {
    translationKey: 'contractorCompanyName',
    property: 'companyName',
  },
  {
    translationKey: 'cityLabel',
    property: 'city',
  },
  {
    translationKey: 'streetAndHouseNumber',
    property: 'street',
  },
  {
    translationKey: 'postalCodeLabel',
    property: 'postalCode',
  },
];

export function ContractorManagement() {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommons } = useTranslationText('commons');

  const [query, setQuery] = useQueryParams({
    ...ContractorQueryParamConfigMap,
    sort: withDefault(StringParam, ContractorSort.CompanyNameAsc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });
  const { data: contractorsPaged, isLoading, isError } = useGetAllContractors({}, query as ContractorQuery);
  const needsPagination = contractorsPaged && contractorsPaged.totalPages > 1;

  const handleFilterChange = (name: keyof ContractorQuery, value: string | string[] | undefined) => {
    setQuery({ ...query, [name]: value, page: DEFAULT_PAGE });
  };

  const onPageChange = (page: number) => {
    setQuery({ ...query, page });
  };

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  return (
    <div className="has-pagination">
      <ActionBar
        center={
          <DebouncedSearchBar
            isLoading={isLoading}
            value={query.companyName ?? ''}
            onChangeDebounced={(newValue) => handleFilterChange('companyName', newValue)}
            placeholder={t('contractorSearchPlaceholder')}
            data-role="contractors-search-input"
          />
        }
        right={
          <Field>
            <Label size="is-small">{tCommons('moreActions')}</Label>
            <NavigateButton
              roleCheckKey="create-new-contractor-btn"
              requiredPermission={UserResourcePermissions.Contractor.Create}
              to="create"
              text={t('createNewContractor')}
              size="is-small"
              icon="add"
              isConfirm
            />
          </Field>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_contractorManagement') : undefined}>
        <div className="overflow" style={{ flex: 1 }}>
          <TableHintWrapper
            table={
              <>
                <AosTable>
                  <TableHeader
                    columns={tableColumns}
                    translationSection="contractManagements"
                    onSortChange={onSortChange}
                    currentSort={query.sort}
                  />
                  <AosTable.Body data-role="contractor-table">
                    {contractorsPaged?.content.map((contractor) => {
                      const routeToDetail = NavigationRoutes.ContractorId(contractor.id);
                      return (
                        <AosTable.Row
                          className="is-clickable"
                          key={contractor.id}
                          data-id={contractor.id}
                          data-role="contractor-item"
                        >
                          <LinkTableCell to={routeToDetail} dataRole="company-name-cell">
                            {contractor.companyName}
                          </LinkTableCell>
                          <LinkTableCell to={routeToDetail} dataRole="city-cell">
                            {contractor.city}
                          </LinkTableCell>
                          <LinkTableCell to={routeToDetail} dataRole="street-and-house-number-cell">
                            {`${contractor.street} ${contractor.houseNumber}`}
                          </LinkTableCell>
                          <LinkTableCell to={routeToDetail} dataRole="postal-code-cell">
                            {contractor.postalCode}
                          </LinkTableCell>
                        </AosTable.Row>
                      );
                    })}
                  </AosTable.Body>
                </AosTable>
                {needsPagination && (
                  <footer className="pagination-footer">
                    <Pagination
                      currentPage={query.page ?? 0}
                      totalPages={contractorsPaged?.totalPages ?? 0}
                      handleOnPageChange={onPageChange}
                      size="is-small"
                    />
                  </footer>
                )}
              </>
            }
            tableResultOption={calculateTableState(contractorsPaged?.content ?? [], isError)}
          />
        </div>
      </LoadingSpinner>
    </div>
  );
}
