import { RelationFilter, TicketRelationDto } from '@/models/operation/RelationTypeModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import { queryStringFromObject } from './QueryUtils';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { TicketingApiPrefix } from './TicketingApi';
import { Page } from '@/models/pagination/Pagination';

export default function useGetRelations(
  ticketId: string,
  filter?: RelationFilter,
  options?: QueryOptions<Page<TicketRelationDto>>,
) {
  const queryString = queryStringFromObject(filter);
  const path = `${TicketingApiPrefix}/ticket/${ticketId}/relation${filter ? `?${queryString}` : ''}`;

  return useGetQuery<Page<TicketRelationDto>>(
    [ServerStateKeysEnum.TicketRelations, ticketId, queryString],
    path,
    options,
  );
}
