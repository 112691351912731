import { TicketSeverities } from '@/models/operation/TicketSeveritiesModel';
import { TicketStates } from '@/models/operation/TicketStatesModel';
import { useTranslationText } from '@/translation/TranslationHooks';
import RichTextContent from '../../../ui/rich-text-content/RichTextContent';
import './HistoryItem.scss';
import useGetAllUsers from '@/react-query/GetUsers';

interface UpdatedHistoryEntry {
  readonly payload: any;
  readonly testId: number;
  readonly severities: TicketSeverities;
  readonly states: TicketStates;
}

function UpdatedHistoryComponent({ payload, testId, severities, states }: UpdatedHistoryEntry) {
  const { t } = useTranslationText('histories');
  const { t: tTickets } = useTranslationText('tickets');
  const { oldValue, newValue, fieldTranslationKey } = payload;
  const { data: users } = useGetAllUsers();

  const updateDetails = (value: string) => {
    switch (value) {
      case 'true':
        value = t('booleanTrue');
        break;
      case 'false':
        value = t('booleanFalse');
        break;
    }
    switch (fieldTranslationKey) {
      case 'SEVERITY':
        return <span>{tTickets(severities?.find((severity) => severity.key === value)?.translationKey || '')}</span>;
      case 'ASSIGNED_COMPONENT':
        return <span>{value || t('nothing')}</span>;
      case 'STATE':
        return <span>{tTickets(states?.find((state) => state.key === value)?.translationKey || '')}</span>;
      case 'TASK_STATE':
        return <span>{tTickets(value)}</span>;
      case 'DESCRIPTION':
        return <RichTextContent value={value} readOnly />;
      case 'ASSIGNEE_ID':
        return <span>{users?.content.find((user) => user.id === value)?.username}</span>;
      case 'OBSERVER_IDS':
        return (
          <span>
            {value
              .split(', ')
              .map((observerId) => users?.content.find((user) => user.id === observerId)?.username)
              .join(', ')}
          </span>
        );

      default:
        return !value || value === '[]' ? <span>{t('nothing')}</span> : value;
    }
  };

  return (
    <>
      <span className="history-value" data-role={`${testId}-oldValue`}>
        {updateDetails(oldValue)}
      </span>
      <span className="history-arrow">→</span>
      <span className="history-value" data-role={`${testId}-newValue`}>
        {updateDetails(newValue)}
      </span>
    </>
  );
}

interface PayloadFactoryProps {
  readonly payload: any;
  readonly testId: number;
  readonly severities: TicketSeverities;
  readonly states: TicketStates;
}

export default function PayloadFactory({ payload, testId, severities, states }: PayloadFactoryProps) {
  if (payload.newValue !== undefined) {
    return <UpdatedHistoryComponent payload={payload} testId={testId} severities={severities} states={states} />;
  }
  return <></>;
}
