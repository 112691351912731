import { Table as AosTable } from '@aos/styleguide-react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { pathForAllContractsWithFilter, useGetAllContracts } from '@/react-query/ContractManagementSystemApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import ActionBar from '../ui/action-bar/ActionBar';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import { ContractQuery, contractQueryParamConfigMap, ContractSort } from '@/models/contract-management/ContractQuery';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import { getNextSortState } from '@/ui/table-sort/TableSort';
import Pagination from '../ui/pagination/Pagination';
import { DebouncedSearchBar } from '@/ui/filter/search-bar/SearchBar';
import { dateByAddingMonths } from '@/models/dates/dateFunctions';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import DateFormats from '../models/date-formats/DateFormats';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import NavigateButton from '../generic-components/navigate-button/NavigateButton';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import FormFieldWrapper from '../ui/form-field-wrapper/FormFieldWrapper';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

export default function ContractManagement() {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  useTranslationText('commons');

  const { currentTenant } = useCurrentTenant();
  const [query, setQuery] = useQueryParams({
    ...contractQueryParamConfigMap,
    sort: withDefault(StringParam, ContractSort.ContractorCompanyNameAsc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });
  const { data: contractsPaged, isLoading, isError } = useGetAllContracts({}, query as ContractQuery);
  const needsPagination = contractsPaged && contractsPaged.page.totalPages > 1;

  const tableColumns: TableColumn[] = [
    { property: 'internalNumber', translationKey: 'internalContractNumberLabel' },
    { property: 'contractualObject', translationKey: 'contractualObject' },
    { property: 'contractor.companyName', translationKey: 'companyName' },
    { property: 'endDate', translationKey: 'endDateLabel' },
  ];

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  const handleFilterChange = (name: keyof ContractQuery, value: string | string[] | number | undefined) => {
    setQuery((q) => ({ ...q, [name]: value, page: DEFAULT_PAGE }));
  };

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  const contractLengthToggle = (
    <FormFieldWrapper label={t('endsInMonths')} isFullWidth={false}>
      <SwitchInput
        id="contract-length-toggle"
        checked={query.endDateUntil !== undefined}
        onChange={() => {
          const date = query.endDateUntil !== undefined ? undefined : dateByAddingMonths(new Date(), 6).toISOString();
          handleFilterChange('endDateUntil', date);
        }}
      />
    </FormFieldWrapper>
  );

  const actionBarDropdown = (
    <Dropdown
      title={<FontAwesomeIcon icon={faEllipsisH} />}
      renderItems={(ctx) => [
        <a
          type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          download
          href={pathForAllContractsWithFilter([currentTenant], query as ContractQuery)}
          className="dropdown-item"
          data-role="export-contracts-button"
          key="export-contracts-button"
          onClick={() => ctx.closeDropdown()}
        >
          {t('exportContracts')}
        </a>,
      ]}
    />
  );

  return (
    <div className="has-pagination">
      <ActionBar
        center={
          <DebouncedSearchBar
            isLoading={isLoading}
            onChangeDebounced={(newValue) => handleFilterChange('query', newValue)}
            placeholder={t('contractSearchPlaceholder')}
            data-role="contract-search-input"
            value={query.query ?? ''}
          />
        }
        right={
          <>
            {contractLengthToggle}
            <NavigateButton
              roleCheckKey="create-new-contract-btn"
              requiredPermission={UserResourcePermissions.Contract.Create}
              to="create"
              text={t('createNewContract')}
              size="is-small"
              icon="add"
              isConfirm
            />
            {actionBarDropdown}
          </>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_contractManagement') : undefined}>
        <div className="overflow" style={{ flex: 1 }}>
          <TableHintWrapper
            table={
              <>
                <AosTable>
                  <TableHeader
                    columns={tableColumns}
                    onSortChange={onSortChange}
                    currentSort={query.sort}
                    translationSection="contractManagements"
                  />
                  <AosTable.Body data-role="contract-table">
                    {contractsPaged?.content.map((contract, index) => (
                      <AosTable.Row
                        className="is-clickable"
                        key={contract.id}
                        data-id={contract.id}
                        data-role="contract-item"
                        data-row={index}
                      >
                        <LinkTableCell to={contract.id} dataRole="contract-internalNumber-cell">
                          {contract.internalNumber}
                        </LinkTableCell>
                        <LinkTableCell to={contract.id} dataRole="contract-object-cell">
                          {contract.contractualObject}
                        </LinkTableCell>
                        <LinkTableCell to={contract.id} dataRole="contract-companyName-cell">
                          {contract.contractor.companyName}
                        </LinkTableCell>
                        <LinkTableCell to={contract.id} dataRole="contract-end-date">
                          {contract.endDate ? dayjs(contract.endDate).format(DateFormats.FULLDATE) : ''}
                        </LinkTableCell>
                      </AosTable.Row>
                    ))}
                  </AosTable.Body>
                </AosTable>
                {needsPagination && (
                  <footer className="pagination-footer">
                    <Pagination
                      currentPage={query.page ?? 0}
                      totalPages={contractsPaged?.page?.totalPages ?? 0}
                      handleOnPageChange={onPageChange}
                      size="is-small"
                    />
                  </footer>
                )}
              </>
            }
            tableResultOption={calculateTableState(contractsPaged?.content ?? [], isError)}
          />
        </div>
      </LoadingSpinner>
    </div>
  );
}
