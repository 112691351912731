import classNames from 'classnames';
import dayjs from 'dayjs';
import { Field, Label, Table as AosTable, Tag } from '@aos/styleguide-react';
import { useQueryParams } from 'use-query-params';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import UserRoleCheck from '../auth/UserRoleCheck';
import DateFormats from '../models/date-formats/DateFormats';
import { useGetAutomationRules } from '@/react-query/AutomationApi';
import useGetAllUsers from '../react-query/GetUsers';
import { useTranslationText } from '@/translation/TranslationHooks';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import './AutomationRules.scss';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import AosActionBar from '../ui/action-bar/AosActionBar';
import { AutomationRuleQuery, AutomationRuleQueryParamConfigMap } from '@/models/operation/AutomationRuleQuery';
import { DebouncedAosSearchBar } from '@/ui/filter/search-bar/AosSearchBar';
import NavigateButton from '../generic-components/navigate-button/NavigateButton';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

export default function AutomationRules() {
  const [query, setQuery] = useQueryParams({ ...AutomationRuleQueryParamConfigMap });

  const { data: rules, isLoading, isError } = useGetAutomationRules(query as AutomationRuleQuery);
  const { data: users, isError: isGetUserError } = useGetAllUsers(rules?.map((rule) => rule.createdBy));

  const { t } = useTranslationText('automations');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommons } = useTranslationText('commons');

  const handleFilterChange = (key: keyof AutomationRuleQuery, value: string | string[] | number[] | undefined) => {
    setQuery((prev) => ({ ...prev, [key]: value }));
  };

  const tableRows = rules?.map((rule) => {
    const createdBy = users?.content.find((user) => user.id === rule.createdBy);
    const routeToDetail = rule.id;
    return (
      <tr className="rule is-clickable" key={rule.name}>
        <LinkTableCell to={routeToDetail} dataRole="rule-name-cell">
          {rule.name}
        </LinkTableCell>
        <LinkTableCell
          to={routeToDetail}
          dataRole="rule-creator-cell"
          className={classNames('created-by', { 'created-by-error': isGetUserError })}
        >
          {!isGetUserError ? `${createdBy?.firstName || ''} ${createdBy?.lastName || ''}` : '?'}
        </LinkTableCell>
        <LinkTableCell to={routeToDetail} dataRole="rule-status">
          <Tag color={rule.active ? 'is-success' : 'is-secondary'} size="is-normal">
            {rule.active ? t('active') : t('inactive')}
          </Tag>
        </LinkTableCell>
        <LinkTableCell to={routeToDetail}>
          {rule.actions.some((action) => action.type.toLowerCase().includes('message')) ? (
            <Tag>{t('notification')}</Tag>
          ) : (
            <Tag>{t('automation')}</Tag>
          )}
        </LinkTableCell>
        <LinkTableCell to={routeToDetail} className="last-modified">
          {dayjs(rule.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}
        </LinkTableCell>
        <LinkTableCell to={routeToDetail} dataRole="rule-last-modified-cell" className="last-modified">
          {dayjs(rule.lastModified).format(DateFormats.FULLDATE_HOURS_MINS)}
        </LinkTableCell>
      </tr>
    );
  });

  return (
    <div className="flex-container">
      <UserRoleCheck requiredPermission={UserResourcePermissions.Rule.Read}>
        <AosActionBar
          left={
            <DebouncedAosSearchBar
              value={query.name ?? ''}
              isLoading={isLoading}
              onChangeDebounced={(newValue) => {
                if (newValue === '') {
                  handleFilterChange('name', undefined);
                } else {
                  handleFilterChange('name', newValue);
                }
              }}
              placeholder={t('automationSearchPlaceholder')}
              data-role="task-search-input"
              size="is-small"
              label={tCommons('search')}
            />
          }
          right={
            <Field>
              <Label size="is-small">{tCommons('moreActions')}</Label>
              <NavigateButton
                key="create-new-automationrule-btn"
                requiredPermission={UserResourcePermissions.Rule.Create}
                to="create"
                text={t('createNewRule')}
                size="is-small"
                icon="add"
                isConfirm
              />
            </Field>
          }
        />
        <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_automationRules') : undefined}>
          <div className="overflow" style={{ flex: 1 }} data-role="rules-overview">
            <TableHintWrapper
              table={
                <AosTable>
                  <AosTable.Header className="sticky-header">
                    <AosTable.Row>
                      <AosTable.HeaderCell>{t('ruleName')}</AosTable.HeaderCell>
                      <AosTable.HeaderCell>{t('createdBy')}</AosTable.HeaderCell>
                      <AosTable.HeaderCell>{t('ruleStatus')}</AosTable.HeaderCell>
                      <AosTable.HeaderCell>{t('ruleType')}</AosTable.HeaderCell>
                      <AosTable.HeaderCell>{t('createdAt')}</AosTable.HeaderCell>
                      <AosTable.HeaderCell>{t('lastModified')}</AosTable.HeaderCell>
                    </AosTable.Row>
                  </AosTable.Header>
                  {rules && rules.length > 0 && <AosTable.Body>{tableRows}</AosTable.Body>}
                </AosTable>
              }
              tableResultOption={calculateTableState(rules ?? [], isError)}
            />
          </div>
        </LoadingSpinner>
      </UserRoleCheck>
    </div>
  );
}
