import { createContext, useContext, useMemo, useReducer } from 'react';
import { DecodedValueMap, QueryParamConfigMap, SetQuery, useQueryParams } from 'use-query-params';
import { ActiveAlertColumn } from '@/models/operation/AlertModel';
import { ActiveAlertQueryParamConfigMap } from '@/models/operation/ActiveAlertQuery';

function useGetSelectedColumns(): ColumnConfigType {
  return [
    {
      headerText: 'Komponente',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'Permanent ID',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'Komponententyp',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: true,
    },
    {
      headerText: 'Funktionsgruppe',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'TLS-Typ',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'Betriebsstatus (Metrik)',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'Zeitstempel',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
    {
      headerText: 'Vertrag',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: true,
    },
    {
      headerText: 'Kritikalität',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: true,
    },
    {
      headerText: 'Unterkomponenten',
      visible: true, // will be overwritten by createInitialColumnConfig
      displayIndex: 0, // will be overwritten by createInitialColumnConfig
      isDropdown: false,
    },
  ];
}

export enum SpecialHeaderTexts {
  DESCENDENT_COMPONENT_STATS = 'Unterkomponenten',
  CRITICALITY = 'Kritikalität',
  TIMESTAMP = 'Zeitstempel',
}

type ColumnConfigActionTypes = 'move-left' | 'move-right' | 'set-visible' | 'set-invisible' | 'apply-config';

type ColumnConfigActionType = {
  type: ColumnConfigActionTypes;
  configToChange: string;
  config?: ColumnConfigType;
};

export type ColumnConfigType = ActiveAlertColumn[];

function createInitialColumnConfig(columnConfig: ColumnConfigType): ColumnConfigType {
  return columnConfig.map(
    (config, index): ActiveAlertColumn => ({
      ...config,
      visible: true,
      displayIndex: index,
    }),
  );
}

function findNeighbor(myIndex: number, state: ColumnConfigType, left = true): ActiveAlertColumn | undefined {
  if (myIndex < 0 || myIndex > state.length - 1) return undefined;
  const neighbor = state.find(
    (potentialNeighbor) => potentialNeighbor.displayIndex === (left ? myIndex - 1 : myIndex + 1),
  );
  if (!neighbor) return undefined;
  if (neighbor.visible) return neighbor;
  return findNeighbor(left ? myIndex - 1 : myIndex + 1, state, left);
}

function stateWithoutConfig(configToRemove: ActiveAlertColumn, state: ColumnConfigType) {
  return state.filter((config) => config.headerText !== configToRemove.headerText);
}

const createReducer =
  (query: DecodedValueMap<QueryParamConfigMap>, setQuery: SetQuery<QueryParamConfigMap>) =>
  (state: ColumnConfigType, action: ColumnConfigActionType): ColumnConfigType => {
    const columnConfigToAlter = state.find((config) => config.headerText === action.configToChange);
    if (!columnConfigToAlter && action.type !== 'apply-config') return state;

    if (action.type !== 'apply-config' && query.name) {
      setQuery({ name: undefined }, 'replaceIn');
    }
    switch (action.type) {
      case 'set-visible': {
        return [
          ...stateWithoutConfig(columnConfigToAlter!, state),
          { ...columnConfigToAlter, visible: true },
        ] as ColumnConfigType;
      }
      case 'set-invisible': {
        return [
          ...stateWithoutConfig(columnConfigToAlter!, state),
          { ...columnConfigToAlter, visible: false },
        ] as ColumnConfigType;
      }
      case 'move-left': {
        const elemToSwapWith = findNeighbor(columnConfigToAlter!.displayIndex, state, true);
        if (!elemToSwapWith) return state;
        return [
          ...stateWithoutConfig(elemToSwapWith, stateWithoutConfig(columnConfigToAlter!, state)),
          { ...columnConfigToAlter, displayIndex: elemToSwapWith.displayIndex },
          { ...elemToSwapWith, displayIndex: columnConfigToAlter!.displayIndex },
        ] as ColumnConfigType;
      }
      case 'move-right': {
        const elemToSwapWith = findNeighbor(columnConfigToAlter!.displayIndex, state, false);
        if (!elemToSwapWith) return state;
        return [
          ...stateWithoutConfig(elemToSwapWith, stateWithoutConfig(columnConfigToAlter!, state)),
          { ...columnConfigToAlter, displayIndex: elemToSwapWith.displayIndex },
          { ...elemToSwapWith, displayIndex: columnConfigToAlter!.displayIndex },
        ] as ColumnConfigType;
      }
      case 'apply-config': {
        if (!action.config) return state;
        const newState = [...state];
        let displayOffset = 0;
        return newState.map((columnConfig) => {
          const configToApply = action.config?.find((config) => config.headerText === columnConfig.headerText);
          if (configToApply) {
            return { ...columnConfig, displayIndex: configToApply.displayIndex, visible: configToApply.visible };
          }
          const columnConfigWithOffset = { ...columnConfig, displayIndex: action.config!.length + displayOffset };
          displayOffset += 1;
          return columnConfigWithOffset;
        });
      }
      default: {
        return state;
      }
    }
  };

type ColumnConfigContextType = {
  columnConfig: ColumnConfigType;
  dispatch: React.Dispatch<ColumnConfigActionType>;
};

const ColumnConfigContext = createContext({} as ColumnConfigContextType);

export function useColumnConfigContext() {
  return useContext(ColumnConfigContext);
}

export function ColumnConfigProvider({ children }: { children: React.ReactNode }) {
  const initialColumnConfig: ColumnConfigType = createInitialColumnConfig(useGetSelectedColumns());
  const [query, setQuery] = useQueryParams({
    ...ActiveAlertQueryParamConfigMap,
  });
  const [state, dispatch] = useReducer(createReducer(query, setQuery), initialColumnConfig);
  const initialColumnConfigContext = useMemo(() => ({ columnConfig: state, dispatch }), [state, dispatch]);

  return <ColumnConfigContext.Provider value={initialColumnConfigContext}>{children}</ColumnConfigContext.Provider>;
}
