import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import { TextInput as AosTextInput } from '@aos/styleguide-react';
import CreateTicketModel from '../../models/operation/CreateTicketModel';
import { MutationKey, MutationPath, usePutMutation } from '@/react-query/MutationQueries';
import {
  TicketMediaUrlBuilders,
  useGetSeverities,
  useGetStates,
  useGetTasksByTicketId,
  useGetTicket,
  useGetTypes,
} from '@/react-query/TicketingSystemApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import { getTypeIcon, mapStatesToOptions, mapStateToOption, mapTypeToOption } from '@/models/operation/TicketFunctions';
import ticketValidationSchema from '../TicketValidationSchema';
import useYupLocal from '../../translation/YupLocal';
import { ReactSelectOption } from '@/ui/search-dropdown/SearchDropdown';
import { Ticket } from '@/models/operation/TicketModel';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import RichTextEditor from '../../ui/rich-text/RichTextEditor';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import ActionBar from '../../ui/action-bar/ActionBar';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import Media from '../../ui/media/Media';
import SingleSelectDropdown from '../../ui/single-select-dropdown/SingleSelectDropdown';
import { useGetComponent, useGetDescendentComponentStats } from '@/react-query/ComponentApi';
import NavigationRoutes from '../../routing/NavigationRoutes';
import ComponentStatusTag from '../../components/component-status-tag/ComponentStatusTag';
import { ComponentDescendentComponents } from '@/components/component-detail-items/ComponentDescendentComponents';
import SingleComponentSelect from '../../components/component-selects/SingleComponentSelect/SingleComponentSelect';
import { allowImagesAndPdf } from '@/models/media/Media';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import { useLongParamId } from '@/custom-hooks/UseParamId';
import FavoriteInput from '../ticket-details/favorite-input/FavoriteInput';
import TicketRelations from '../ticket-relations/TicketRelations';
import TicketTabs from '../tabs/TicketTabs';
import './EditTicket.scss';
import ActorTextInput from '@/ui/ActorTextInput';
import DetailInfoField from '@/ui/detail-info-field/DetailInfoField';
import { formatDateAndTime } from '@/utils/dateFormatting';
import { TicketContracts } from '@/ticketing/contract/TicketContracts';
import TicketArticles from '@/ticketing/articles/TicketArticles';
import { TicketAlerts } from '@/ticketing/alerts/TicketAlerts';
import SingleSeveritySelect from '@/ticketing/severity-selects/single-severity-select/SingleSeveritySelect';
import useGetAllAssignableActors from '@/react-query/UseGetAllAssignableActors';
import { AssignableActorSelect } from '@/ticketing/assignable-actor-select/AssignableActorSelect';
import { AssignableActorMultiSelect } from '@/ticketing/assignable-actor-select/AssignableActorMultiSelect';

function formattedStateLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export function formattedTypeLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="type-icon-wrapper">{getTypeIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

export default function EditTicket() {
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);
  const [lastHistoryUpdate, setLastHistoryUpdate] = useState(Date.now());
  const [relationsChanged, setRelationsChanged] = useState<boolean>(false);
  const { id: ticketId, ErrorPage } = useLongParamId();

  const {
    data: currentTicket,
    isLoading: isTicketLoading,
    error: getTicketFetchError,
    refetch,
  } = useGetTicket(ticketId, {
    refetchOnWindowFocus: false,
  });

  const { data: ticketComponent } = useGetComponent(currentTicket?.assignedComponent?.id!, {
    enabled: !!currentTicket?.assignedComponent,
    suppressErrorToast: true,
  });

  const { hasPermission } = useAuthUser();

  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const { handleSubmit, control, formState } = useForm<CreateTicketModel>({
    mode: 'onChange',
    resolver: yupResolver(ticketValidationSchema(yup)),
  });

  const handleTicketRelationRefresh = async () => {
    setRelationsChanged(true);
    setLastHistoryUpdate(Date.now());
    await refetch();
  };

  usePreventNavigation(
    Object.keys(formState.dirtyFields).length > 0 && preventNavigation,
    tCommon('discardOpenChangesQuestion'),
  );

  const navigate = useNavigate();

  const { data: states, isLoading: isStatesLoading, error: getStatesFetchError } = useGetStates();
  const { data: types, isLoading: isTypesLoading, error: getTypesFetchError } = useGetTypes();
  const { data: severities, isLoading: isSeverityLoading, error: getSeverityFetchError } = useGetSeverities();

  const { data: assignableActors, isLoading: areActorsLoading, isError: isActorsError } = useGetAllAssignableActors();

  const { mutate, isPending } = usePutMutation<CreateTicketModel, Ticket>(MutationKey.PutTicket, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('updateTicket'));
    },
    onError: () => {
      toast.error(tError('createTicketError'));
    },
  });

  const onSubmit = (ctm: CreateTicketModel) => {
    setPreventNavigation(false);

    mutate({
      body: {
        title: ctm.title,
        severity: ctm.severity,
        description: ctm.description,
        assignedComponent: ctm.assignedComponent,
        media: ctm.media,
        assigneeId: ctm.assigneeId,
        state: ctm.state,
        reportableProcess: ctm.reportableProcess,
        type: ctm.type,
        observerIds: ctm.observerIds,
      },
      path: MutationPath.PutTicket(ticketId.toString()),
    });
  };

  const { data: descendentComponentStats, isLoading: isDescendentComponentStatsLoading } =
    useGetDescendentComponentStats(ticketComponent?.id || '', 'part-of', { enabled: !!ticketComponent });

  const shouldShowStatusForDescendentComponents =
    descendentComponentStats &&
    descendentComponentStats.healthy + descendentComponentStats.disrupted + descendentComponentStats.failed > 0;

  const { data: taskData, isLoading: areTasksLoading } = useGetTasksByTicketId(ticketId, { enabled: !!ticketId });

  const TitleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'title'> }) => (
      <FormFieldWrapper error={formState.errors?.title} isRequired label={t('titleLabel')}>
        <AosTextInput
          value={field.value}
          onChange={(newValue) => field.onChange(newValue)}
          placeholder={t('titlePlaceholder')}
          disabled={!hasPermission(UserResourcePermissions.Ticket.Update)}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.title, hasPermission, t],
  );

  const DescriptionTextArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'description'> }) => (
      <FormFieldWrapper error={formState.errors?.description} label={t('descriptionLabel')} noGridControl>
        <RichTextEditor
          id="ticketDescription"
          placeholder={t('descriptionPlaceholder')}
          onChange={field.onChange}
          value={field.value}
          error={formState.errors?.description?.message}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.description, t],
  );

  const AssigneeSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'assigneeId'> }) => (
      <FormFieldWrapper
        error={formState.errors?.assigneeId}
        label={t('assignedUser')}
        isRequired
        isLoading={areActorsLoading}
      >
        <AssignableActorSelect
          assignableActors={assignableActors}
          isError={isActorsError}
          isLoading={areActorsLoading}
          onChange={(value) => field?.onChange(value?.value)}
          placeholder={t('assignUser')}
          requiredPermission={UserResourcePermissions.Ticket.Create}
          selectedActorId={field.value}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [areActorsLoading, assignableActors, formState.errors?.assigneeId, isActorsError, t],
  );

  const Attachments = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'media'> }) => (
      <Media
        mutationPath={MutationPath.UploadTicketMedia()}
        mutationKey={MutationKey.PostUploadTicketMedia}
        media={field.value}
        urlBuilders={TicketMediaUrlBuilders}
        isEditable
        isSideView
        supportedFileTypes={allowImagesAndPdf}
        onMediaChange={field.onChange}
        label={t('attachments')}
      />
    ),
    [t],
  );

  const ObserversSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'observerIds'> }) => (
      <FormFieldWrapper
        error={formState.errors?.observerIds}
        label={t('assignedObservers')}
        isLoading={areActorsLoading}
      >
        <AssignableActorMultiSelect
          assignableActors={assignableActors}
          selectedActorsId={assignableActors?.content?.filter((actor) => field.value?.includes(actor.id ?? ''))}
          isError={isActorsError}
          isLoading={isActorsError}
          onChange={(value) => field?.onChange(value.map((it) => it.value) ?? [])}
          placeholder={t('assignedObservers')}
          requiredPermission={UserResourcePermissions.Ticket.Create}
        />
      </FormFieldWrapper>
    ),
    [areActorsLoading, assignableActors, formState.errors?.observerIds, isActorsError, t],
  );

  const SeveritiesSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'severity'> }) => (
      <SingleSeveritySelect
        onChange={(value) => {
          if (value && value.key !== field.value) {
            field.onChange(value.key);
          }
        }}
        value={field.value}
        label={t('fieldSeverity')}
      />
    ),
    [t],
  );

  const StateSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'state'> }) => (
      <FormFieldWrapper label={t('fieldState')}>
        <SingleSelectDropdown
          requiredPermission={UserResourcePermissions.Ticket.Update}
          isLoading={isStatesLoading}
          options={mapStatesToOptions(t, states)}
          value={mapStateToOption(
            t,
            states?.find((state) => state.key === field.value),
          )}
          onChange={(newState) => {
            if (newState && newState?.value !== field.value) {
              field.onChange(newState?.value);
            }
          }}
          formatOptionLabel={formattedStateLabel}
        />
      </FormFieldWrapper>
    ),
    [isStatesLoading, states, t],
  );

  const ComponentSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'assignedComponent'> }) => (
      <SingleComponentSelect
        error={formState.errors?.assignedComponent}
        onChange={(newValue) => {
          if (newValue && newValue !== field.value) {
            field.onChange(newValue);
          }
        }}
        value={field.value ?? null}
      />
    ),
    [formState.errors?.assignedComponent],
  );

  const ReportableProcessSwitch = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'reportableProcess'> }) => (
      <FormFieldWrapper error={formState.errors?.reportableProcess} label={t('reportableProcess')}>
        <UserRoleCheck
          requiredPermission={UserResourcePermissions.TicketReportableProcess.Create.and(
            UserResourcePermissions.TicketReportableProcess.Delete,
          )}
        >
          <SwitchInput
            id="ticket-reportable-process"
            onChange={(newValue) => field.onChange(newValue)}
            checked={field.value}
            secondLabel={field.value ? 'Ja' : 'Nein'}
          />
        </UserRoleCheck>
      </FormFieldWrapper>
    ),
    [formState.errors?.reportableProcess, t],
  );

  const TypeSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'type'> }) => (
      <FormFieldWrapper error={formState.errors?.type} label={t('fieldType')}>
        <SingleSelectDropdown
          requiredPermission={UserResourcePermissions.Ticket.Update}
          isLoading={isTypesLoading}
          options={[]}
          value={mapTypeToOption(
            t,
            types?.find((type) => type.key === field.value),
          )}
          onChange={() => undefined}
          formatOptionLabel={formattedTypeLabel}
          disabled
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.type, isTypesLoading, types, t],
  );

  const errors = useErrorsCollector([
    {
      fetchError: getSeverityFetchError,
      errorText: '404_severity',
    },
    {
      fetchError: getStatesFetchError,
      errorText: '404_ticketsStates',
    },
    {
      fetchError: getTypesFetchError,
      errorText: '404_ticketsStates',
    },
    {
      fetchError: getTicketFetchError,
      errorText: <Trans i18nKey="errorTexts.404_ticket.text" values={{ id: ticketId }} />,
    },
  ]);

  if (ErrorPage) {
    return ErrorPage;
  }

  return (
    <LoadingSpinner isLoading={isTicketLoading || isSeverityLoading || isStatesLoading} errors={errors}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex-container">
        <ActionBar
          right={
            <>
              <button
                className="button is-primary text-icon-button"
                type="button"
                aria-label="cancel-button"
                data-role="ticket-cancel-button"
                onClick={() => {
                  setPreventNavigation(false);
                  navigate(-1);
                }}
              >
                <span>{tCommon('abort')}</span>
              </button>
              <button
                className={classNames('button is-primary text-icon-button', { 'is-loading': isPending })}
                type="submit"
                aria-label="save-button"
                data-role="ticket-save-button"
                disabled={!(formState.isValid && Object.keys(formState.dirtyFields).length > 0) && !relationsChanged}
              >
                <span>
                  {tCommon('update')}
                  <FontAwesomeIcon icon={faSave} />
                </span>
              </button>
            </>
          }
        />
        <div className="columns is-5 no-overflow-content">
          <div className="edit-ticket column is-7">
            <div className="wrapper">
              <div className="severities-dropdown">
                <Controller
                  render={SeveritiesSelect}
                  name="severity"
                  control={control}
                  defaultValue={currentTicket?.severity}
                />
              </div>
              <DetailInfoField label={t('number')} value={ticketId} />
              <Controller name="title" control={control} render={TitleInput} defaultValue={currentTicket?.title} />
            </div>

            <div className="component-wrapper">
              <div className="component-and-link-wrapper">
                <Controller
                  render={ComponentSelect}
                  name="assignedComponent"
                  control={control}
                  defaultValue={
                    currentTicket?.assignedComponent
                      ? {
                          id: currentTicket.assignedComponent.id,
                          displayName: currentTicket.assignedComponent.displayName,
                          displayNameAlt1: currentTicket.assignedComponent.displayNameAlt1,
                          permanentId: currentTicket.assignedComponent.permanentId,
                          isFavorite: currentTicket.assignedComponent.isFavorite,
                          componentTypeId: 'system-Logical',
                        }
                      : null
                  }
                />
                {ticketComponent && (
                  <>
                    <Link
                      to={NavigationRoutes.ComponentId(ticketComponent.id)}
                      className="without-label-wrapper"
                      title={t('linkToComponentTooltip', { componentName: ticketComponent.displayName })}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </Link>

                    <div className="without-label-wrapper">
                      <ComponentStatusTag status={ticketComponent.status} />
                    </div>
                  </>
                )}
              </div>
              {ticketComponent && (
                <div className="descendant-component-status-wrapper">
                  {shouldShowStatusForDescendentComponents && (
                    <ComponentDescendentComponents
                      label={t('statusDescendentComponents')}
                      isLoading={isDescendentComponentStatsLoading}
                      descendentComponentStats={descendentComponentStats}
                    />
                  )}
                </div>
              )}
            </div>

            <Controller
              name="description"
              control={control}
              render={DescriptionTextArea}
              defaultValue={currentTicket?.description}
            />
            <div className="edit-ticket">
              <Controller name="media" control={control} render={Attachments} defaultValue={currentTicket?.media} />
            </div>
            <div className="tabs-wrapper">
              <TicketTabs
                ticketId={ticketId}
                severities={severities ?? []}
                states={states ?? []}
                lastHistoryUpdate={lastHistoryUpdate}
              />
            </div>
          </div>

          <div className="edit-ticket column is-5">
            <Controller render={StateSelect} name="state" control={control} defaultValue={currentTicket?.state} />
            <Controller render={TypeSelect} name="type" control={control} defaultValue={currentTicket?.type} />
            {currentTicket && <ActorTextInput actor={currentTicket.author} label={t('fieldAuthor')} readOnly />}
            <Controller
              render={AssigneeSelect}
              name="assigneeId"
              control={control}
              defaultValue={currentTicket?.assigneeId}
            />
            <Controller
              render={ObserversSelect}
              name="observerIds"
              control={control}
              defaultValue={currentTicket?.observerIds}
            />
            {currentTicket && <TicketRelations ticket={currentTicket} onRelationChange={handleTicketRelationRefresh} />}

            {currentTicket && (
              <div className="form-row">
                <div className="is-flex-grow">
                  <UserRoleCheck
                    requiredPermission={UserResourcePermissions.TicketFavorite.Create.and(
                      UserResourcePermissions.TicketFavorite.Delete,
                    )}
                  >
                    <FavoriteInput ticket={currentTicket} />
                  </UserRoleCheck>
                </div>
                <div className="is-flex-grow">
                  <Controller
                    render={ReportableProcessSwitch}
                    name="reportableProcess"
                    control={control}
                    defaultValue={currentTicket?.reportableProcess}
                  />
                </div>
              </div>
            )}
            <div className="form-row">
              <DetailInfoField
                className="is-flex-grow"
                label={t('createdAtLabel')}
                value={currentTicket ? formatDateAndTime(currentTicket.createdAt) : '-'}
              />
              <DetailInfoField
                className="is-flex-grow"
                label={t('modifiedAtLabel')}
                value={currentTicket ? formatDateAndTime(currentTicket.lastModified) : '-'}
              />
            </div>
            <div className="ticket-details">
              <TicketContracts component={ticketComponent} taskData={taskData} areTasksLoading={areTasksLoading} />
              <TicketArticles ticketComponent={ticketComponent} />
              <UserRoleCheck requiredPermission={UserResourcePermissions.TicketAlerts.Read}>
                <TicketAlerts ticketId={ticketId} />
              </UserRoleCheck>
            </div>
          </div>
        </div>
      </form>
    </LoadingSpinner>
  );
}
