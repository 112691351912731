import SearchDropdown, { ReactSelectOption } from '@/ui/search-dropdown/SearchDropdown';
import { Page } from '@/models/pagination/Pagination';
import { AssignableActor } from '@/models/actor/Actor';
import { useTranslationText } from '@/translation/TranslationHooks';
import { SingleValue } from 'react-select';
import { UserPermission } from '@/auth/UserPermission';
import { mapAssignableActorToOption } from '@/models/operation/TicketFunctions';

interface AssignableActorSelectProps {
  readonly assignableActors?: Page<AssignableActor>;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly onChange: (value: SingleValue<ReactSelectOption>) => void;
  readonly placeholder?: string;
  readonly isRequired?: boolean;
  readonly requiredPermission: UserPermission;
  readonly selectedActorId?: string;
}

export function AssignableActorSelect({
  assignableActors,
  isError,
  isLoading,
  onChange,
  placeholder,
  isRequired,
  requiredPermission,
  selectedActorId,
}: AssignableActorSelectProps) {
  const { t } = useTranslationText('tickets');

  return (
    <SearchDropdown<AssignableActor | null>
      isError={isError}
      isLoading={isLoading}
      onChange={onChange}
      noOptionsMessage={t('noSuggestionsUsers')}
      isLoadingMessage={t('usersAreLoading')}
      mapValueToSelectOption={mapAssignableActorToOption}
      value={assignableActors?.content?.find((actor) => actor.id === selectedActorId)}
      placeholder={placeholder}
      isRequired={isRequired}
      requiredPermission={requiredPermission}
      options={assignableActors?.content?.map((actor) => mapAssignableActorToOption(actor)) ?? []}
    />
  );
}
