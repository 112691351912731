export type ActorKind = 'ANONYMOUS' | 'USER' | 'SERVICE' | 'AUTOMATION_RULE' | 'MONITORING' | 'EMAIL' | 'GROUP';

export const AllActorKinds: ActorKind[] = [
  'ANONYMOUS',
  'USER',
  'SERVICE',
  'AUTOMATION_RULE',
  'MONITORING',
  'EMAIL',
  'GROUP',
];

export interface Actor {
  kind: ActorKind;
  id?: string;
  email?: string;
}

export interface GroupActor extends Actor {
  name: string;
  tenant: string;
}

export interface UserActor extends Actor {
  firstName?: string;
  lastName?: string;
  username: string;
  mobilPhone?: string;
  phone?: string;
  fullName?: string;
}

export type AssignableActor = GroupActor | UserActor;

export interface AssignableActorFilter {
  query?: string;
  size?: string;
  page?: string;
  userIds?: string[];
  emails?: string[];
}

export function toQueryParam(a: Actor): string {
  return a.id ? `${a.kind}:${a.id}` : a.kind;
}

export function fromQueryParam(queryParam: string): Actor {
  const splitIndex = queryParam.indexOf(':');
  if (splitIndex !== -1) {
    return {
      kind: queryParam.substring(0, splitIndex),
      id: queryParam.substring(splitIndex + 1),
    } as Actor;
  }

  return {
    kind: queryParam,
  } as Actor;
}

export function fromQueryParams(queryParams?: string[]): Actor[] | undefined {
  return queryParams?.map(fromQueryParam);
}

export function actorToDisplayNameWithMail(actor: AssignableActor): string {
  switch (actor.kind) {
    case 'USER':
      return userActorToDisplayNameWithMail(actor as UserActor);
    case 'GROUP':
      return groupActorToDisplayNameWithMail(actor as GroupActor);
  }
  return '';
}

function userActorToDisplayNameWithMail(actor: UserActor): string {
  const displayName = actor.fullName ?? actor.username;
  if (actor.email) {
    return `${actor.fullName} [${actor.email}]`;
  }
  return displayName;
}

function groupActorToDisplayNameWithMail(actor: GroupActor): string {
  if (actor.email) {
    return `${actor.name} [${actor.email}]`;
  }
  return actor.name;
}
