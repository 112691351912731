import MultiSelectDropdown, { ReactSelectOption } from '@/ui/multi-select-dropdown/MultiSelectDropdown';
import { mapAssignableActorsToOption, mapAssignableActorToOption } from '@/models/operation/TicketFunctions';
import { Page } from '@/models/pagination/Pagination';
import { AssignableActor } from '@/models/actor/Actor';
import { UserPermission } from '@/auth/UserPermission';
import { useTranslationText } from '@/translation/TranslationHooks';
import { MultiValue } from 'react-select';

interface AssignableActorMultiSelectProps {
  readonly assignableActors?: Page<AssignableActor>;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly onChange: (value: MultiValue<ReactSelectOption<AssignableActor[] | null>>) => void;
  readonly placeholder?: string;
  readonly requiredPermission: UserPermission;
  readonly selectedActorsId?: AssignableActor[];
}

export function AssignableActorMultiSelect({
  assignableActors,
  isError,
  isLoading,
  onChange,
  placeholder,
  requiredPermission,
  selectedActorsId,
}: AssignableActorMultiSelectProps) {
  const { t } = useTranslationText('tickets');

  return (
    <MultiSelectDropdown<AssignableActor[] | null>
      isError={isError}
      isLoading={isLoading}
      onChange={onChange}
      noOptionsMessage={t('noSuggestionsUsers')}
      loadingMessage={t('usersAreLoading')}
      placeholder={placeholder}
      requiredPermission={requiredPermission}
      options={assignableActors?.content?.map((actor) => mapAssignableActorToOption(actor)) ?? []}
      mappedValues={mapAssignableActorsToOption(selectedActorsId)}
      openMenuOnClick
    />
  );
}
