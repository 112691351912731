import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Table as AosTable } from '@aos/styleguide-react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useGetActiveAlertFilter, useGetAllActiveAlerts } from '@/react-query/AlertApi';
import { ActiveAlert } from '@/models/operation/AlertModel';
import ActionBar from '../../ui/action-bar/ActionBar';
import { useTranslationText } from '@/translation/TranslationHooks';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import Pagination from '../../ui/pagination/Pagination';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import { ActiveAlertQueryParamConfigMap } from '@/models/operation/ActiveAlertQuery';
import './ActiveAlertOverview.scss';
import FilterMenuButton from '../../ui/filter/FilterMenuButton';
import ActiveAlertFilter from '../active-alert-filter/ActiveAlertFilter';
import useToggle from '../../custom-hooks/useToggle';
import { ActiveAlertFilterModel } from '@/models/operation/ActiveAlertFilterModel';
import FilterDropdown from '../../generic-components/filter-dropdown/FilterDropdown';
import { ColumnVisibilityDropdown } from './ColumnVisibilityDropdown';
import { SpecialHeaderTexts, useColumnConfigContext } from './ColumnConfig';
import { ActiveAlertTd } from './ActiveAlertTd';
import { ActiveAlertTableHeader } from './ActiveAlertTableHeader';
import { DebouncedSearchBar } from '@/ui/filter/search-bar/SearchBar';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

interface ActiveAlertTableProps {
  activeAlerts: ActiveAlert[];
  isError: boolean;
  paginationFooter: ReactElement;
}

function ActiveAlertTable({ activeAlerts, isError, paginationFooter }: ActiveAlertTableProps) {
  const { columnConfig } = useColumnConfigContext();

  return (
    <div className="component-table-container" data-role="components-container">
      <TableHintWrapper
        noFlex
        table={
          <>
            <AosTable>
              <ActiveAlertTableHeader />
              <AosTable.Body>
                {activeAlerts.map((activeAlert) => (
                  <AosTable.Row key={activeAlert.id} className="is-clickable">
                    {columnConfig
                      .filter((column) => column.visible)
                      .toSorted((elemA, elemB) => (elemA.displayIndex < elemB.displayIndex ? -1 : 1))
                      .map((column) => (
                        <ActiveAlertTd
                          key={`${column.headerText}_${activeAlert.id}`}
                          columnName={column.headerText}
                          activeAlertInfo={activeAlert}
                          link={`/components/overview/${activeAlert.componentId}`}
                        />
                      ))}
                  </AosTable.Row>
                ))}
              </AosTable.Body>
            </AosTable>
            {activeAlerts && activeAlerts.length > 0 && paginationFooter}
          </>
        }
        tableResultOption={calculateTableState(activeAlerts ?? [], isError)}
      />
    </div>
  );
}

export default function ActiveAlertOverview() {
  const { t } = useTranslationText('alerts');
  const { t: tCommons } = useTranslationText('commons');
  const [activeAlerts, setActiveAlerts] = useState<ActiveAlert[]>([]);
  const [pauseRefetching, setPauseRefetching] = useState(false);
  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);
  const [query, setQuery] = useQueryParams({
    ...ActiveAlertQueryParamConfigMap,
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
    sort: withDefault(StringParam, SpecialHeaderTexts.TIMESTAMP),
  });
  const { columnConfig, dispatch } = useColumnConfigContext();

  const {
    data: pagedActiveAlerts,
    status,
    refetch,
  } = useGetAllActiveAlerts(
    {
      refetchInterval: pauseRefetching ? false : 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    },
    query,
  );

  function onPageChange(page: number) {
    if (page !== 0) setPauseRefetching(true);
    setQuery((prevQuery) => ({ ...prevQuery, page }));
  }

  useEffect(() => {
    if (!pagedActiveAlerts || status !== 'success') return;
    if (pagedActiveAlerts.content.length === 0 && pagedActiveAlerts.totalElements > 0) {
      setQuery((prevQuery) => ({ ...prevQuery, page: DEFAULT_PAGE }));
      return;
    }
    setActiveAlerts(pagedActiveAlerts.content);
  }, [pagedActiveAlerts, setQuery, status]);

  function handleToggleAutoRefetch() {
    setPauseRefetching((prevPause) => {
      if (prevPause) void refetch();
      return !prevPause;
    });
  }

  const resetActiveAlertQuery = () => {
    setQuery(
      {
        name: undefined,
        appliedFilter: undefined,
      },
      'replaceIn',
    );
  };

  function getUnfilteredColumns(alsoFilteredColumn?: string) {
    const appliedFilterKeys = query.appliedFilter ? Object.keys(query.appliedFilter) : [];
    return columnConfig
      .filter(
        (column) =>
          column.visible &&
          !appliedFilterKeys.includes(column.headerText) &&
          column.headerText !== SpecialHeaderTexts.DESCENDENT_COMPONENT_STATS.valueOf() &&
          column.headerText !== alsoFilteredColumn,
      )
      .map((column) => column.headerText);
  }

  const handleFilterChange = (key: string, value: string | string[], remove: boolean = false) => {
    const unfilteredColumns = getUnfilteredColumns(key);
    if (remove) {
      setQuery((prevQuery) => {
        const newQuery = {
          ...prevQuery,
          name: undefined,
          appliedFilter: prevQuery?.appliedFilter ? { ...prevQuery.appliedFilter } : undefined,
          unfilteredColumns: prevQuery?.unfilteredColumns ? [...prevQuery.unfilteredColumns] : undefined,
        };
        delete newQuery.appliedFilter[key];
        if (!Object.keys(newQuery.appliedFilter).length) {
          newQuery.appliedFilter = undefined;
        }
        newQuery.unfilteredColumns = [...(prevQuery?.unfilteredColumns ?? []), key];
        return newQuery;
      }, 'replaceIn');
      return;
    }
    setQuery(
      (prevQuery) => ({
        ...prevQuery,
        name: undefined,
        appliedFilter: { ...prevQuery.appliedFilter, [key]: value },
        unfilteredColumns: [...unfilteredColumns.filter((column) => column !== key)],
      }),
      'replaceIn',
    );
  };

  const handleSearchChange = (newValue: string) => {
    const unfilteredColumns = getUnfilteredColumns();

    setQuery(
      {
        searchText: newValue.length ? newValue : undefined,
        unfilteredColumns: unfilteredColumns.length ? unfilteredColumns : undefined,
      },
      'replaceIn',
    );
  };

  function handleUnselectFilter() {
    setQuery(
      {
        name: undefined,
      },
      'replaceIn',
    );
  }

  const parseActiveAlertFilterValues = useCallback((values: any) => {
    const parsedFilter: Record<string, string | string[]> = {};
    Object.entries(values).forEach((filterKeyValue) => {
      parsedFilter[filterKeyValue[0]] = filterKeyValue[1] as string | string[];
    });
    return parsedFilter;
  }, []);

  function applySelectedFilter(filter?: ActiveAlertFilterModel) {
    if (!filter) {
      resetActiveAlertQuery();
      return;
    }
    dispatch({ type: 'apply-config', configToChange: 'all', config: filter.columnConfig });
    setQuery(
      {
        name: filter.name,
        appliedFilter: parseActiveAlertFilterValues(filter.appliedFilter),
      },
      'replaceIn',
    );
  }

  return (
    <div className="has-pagination">
      <ActionBar
        right={
          <div className="is-flex is-justify-content-center is-align-items-center column-overview-action-bar-gap">
            <div>
              <label className="label" htmlFor="furtherActions">
                {tCommons('furtherActions')}
              </label>
              <div
                id="furtherActions"
                className="is-flex is-justify-content-center is-align-items-center column-overview-action-bar-gap"
              >
                <ColumnVisibilityDropdown />
                <FilterDropdown
                  query={query}
                  useGetAllFilter={useGetActiveAlertFilter}
                  applySelectedFilter={(filter) => applySelectedFilter(filter as ActiveAlertFilterModel)}
                  resetFilterQuery={resetActiveAlertQuery}
                  handleUnselectFilter={handleUnselectFilter}
                  className="filter-select-dropdown"
                  isFullWidth={false}
                />
              </div>
            </div>
            <FormFieldWrapper label="Daten automatisch aktualisieren: " isFullWidth={false}>
              <SwitchInput id="auto-refresh-toggle" checked={!pauseRefetching} onChange={handleToggleAutoRefetch} />
            </FormFieldWrapper>
            <FilterMenuButton
              query={query}
              toggleMenu={toggleIsFilterHidden}
              countWhen={(propertyName) => propertyName === 'appliedFilter'}
            />
          </div>
        }
        center={
          <div className="is-flex is-justify-content-center is-align-items-center column-overview-action-bar-gap">
            <DebouncedSearchBar
              className="active-alert-search-bar"
              placeholder={t('alertSearchPlaceholder')}
              data-role="alert-search-input"
              isLoading={status === 'pending'}
              onChangeDebounced={handleSearchChange}
              value={query.searchText ?? ''}
              autoComplete="on"
            />
          </div>
        }
      />
      <LoadingSpinner isLoading={status === 'pending'}>
        <ActiveAlertTable
          activeAlerts={activeAlerts}
          isError={status === 'error'}
          paginationFooter={
            <footer className="pagination-footer">
              <Pagination
                currentPage={query.page ?? 0}
                totalPages={pagedActiveAlerts?.totalPages ?? 0}
                handleOnPageChange={onPageChange}
                size="is-small"
              />
            </footer>
          }
        />
      </LoadingSpinner>
      <ActiveAlertFilter
        activeAlertQuery={query}
        isFilterHidden={isFilterHidden}
        hideFilter={toggleIsFilterHidden}
        resetActiveAlertFilter={resetActiveAlertQuery}
        availableFilter={columnConfig}
        handleFilterChange={handleFilterChange}
        excludedColumns={[SpecialHeaderTexts.DESCENDENT_COMPONENT_STATS]}
        handleUnselectFilter={handleUnselectFilter}
        applySelectedFilter={applySelectedFilter}
      />
    </div>
  );
}
