import { Collapsible, Hint } from '@aos/styleguide-react';
import { ComponentItem } from '../../models/operation/ComponentModel';
import useRuntimeConfig from '../../runtime-config/RuntimeConfig';
import { useTranslationText } from '@/translation/TranslationHooks';

interface ComponentMetricsProps {
  title: string;
  component: ComponentItem;
}

export function ComponentMetrics({ title, component }: Readonly<ComponentMetricsProps>) {
  const [runtimeConfig] = useRuntimeConfig();
  const buildQueryParams = () => {
    let queryString = '?kiosk=tv&theme=light&orgId=1';
    if (component.technicalIdentifiers) {
      Object.keys(component.technicalIdentifiers).forEach((key) => {
        queryString += `&${encodeURIComponent(`var-${key}`)}=${encodeURIComponent(
          component.technicalIdentifiers[key],
        )}`;
      });
    }
    return queryString;
  };

  const queryParams = buildQueryParams();
  const { t } = useTranslationText('components');

  return (
    <Collapsible header={title} isInitiallyCollapsed={component?.hasDashboardPanels !== true}>
      <div className="component-attributes-dashboard-container">
        {component && runtimeConfig && (
          <>
            {component.hasDashboardPanels === true ? (
              <iframe
                title={component.displayName}
                className="component-attributes-metrics-panel"
                src={`${runtimeConfig.dashboardUrl}/${component.dashboardId}${queryParams}`}
              />
            ) : (
              <>
                <div id="error-wrapper">
                  <Hint color="is-info" icon="circle-info" title={t('noDashboard')} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Collapsible>
  );
}
