import { DescendentComponentStats } from './ComponentQuery';

export type ComponentStatus = 'HEALTHY' | 'DISRUPTED' | 'FAILED' | 'UNKNOWN' | 'NULL';
export const AllComponentStatus: ComponentStatus[] = ['HEALTHY', 'DISRUPTED', 'FAILED', 'UNKNOWN'];
export const AlertingRulesComponentStatus: ComponentStatus[] = ['DISRUPTED', 'FAILED'];

export enum RelationTypeView {
  PART_OF = 'part-of',
  TABLE_VIEW = 'Tabellenansicht',
}

export interface ComponentAttribute {
  name: string;
  value: string;
}

export interface BaseComponentItem {
  id: string;
  displayName: string;
  displayNameAlt1?: string;
  permanentId?: string;
  isFavorite: boolean;
}

export interface BaseComponentItemWithType extends BaseComponentItem {
  componentTypeId: string;
}

export interface Epsg3857Coordinates {
  x: number;
  y: number;
}

export interface SummarizedComponent extends BaseComponentItem {
  componentType: string;
  status: ComponentStatus;
  descendentComponentStats: DescendentComponentStats | null;
  inMaintenance: boolean;
}

export interface ComponentItem extends SummarizedComponent {
  technicalIdentifiers: { [key: string]: string };
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  purchaseDate: Date | null;
  location: string | null;
  supportEndDate: Date | null;
  documentation: string | null;
  componentTypeId: string;
  dashboardId: string;
  attributes: ComponentAttribute[];
  assignedContractId: string | null;
  coordinates: Epsg3857Coordinates | null;
  systemId: string | null;
  systemStageId: string | null;
  hasDashboardPanels: boolean | null;
}

export interface LocatedComponent {
  id: string;
  displayName: string;
  componentType: string;
  componentTypeId: string;
  status: ComponentStatus;
  coordinates: Epsg3857Coordinates;
  icon: string;
}
