import './AllTickets.scss';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useCallback } from 'react';
import { Controls, Field, Label } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import { Ticket } from '@/models/operation/TicketModel';
import { TicketQuery, TicketQueryParamConfigMap, TicketSort } from '@/models/operation/TicketQuery';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { TicketingItem } from '../item/TicketingItem';
import Pagination from '../../ui/pagination/Pagination';
import { pathForAllTicketsWithFilter, useGetAllTickets } from '@/react-query/TicketingSystemApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import useToggle from '../../custom-hooks/useToggle';
import MultiSelectContainer from '../../ui/multiselect/multiselect-container/MultiSelectContainer';
import TicketMultiSelectContext from '../TicketMultiSelectContext';
import TicketFilter from '../filter/TicketFilter';
import { TicketFilterModel } from '@/models/operation/TicketFilterModel';
import AosActionBar from '../../ui/action-bar/AosActionBar';
import { DebouncedAosSearchBar } from '@/ui/filter/search-bar/AosSearchBar';
import AosExportButton from '../export-button/AosExportButton';
import AosFilterMenuButton from '../../ui/filter/AosFilterMenuButton';
import NavigateButton from '../../generic-components/navigate-button/NavigateButton';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import { TableResultOption } from '@/models/wrapped-table/WrappedTableTypes';
import HintWrapper from '@/ui/hint-wrapper/HintWrapper';

export default function AllTickets() {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');

  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);

  const [query, setQuery] = useQueryParams({
    ...TicketQueryParamConfigMap,
    sort: withDefault(StringParam, TicketSort.CreatedAtDesc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });
  const { currentTenant } = useCurrentTenant();

  const { data: ticketsPaged, isLoading } = useGetAllTickets({}, query as TicketQuery);

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  const handleFilterChange = (name: keyof TicketQuery, value: string | string[] | number[] | undefined) => {
    setQuery((prev) => ({ ...prev, name: undefined, [name]: value, sort: TicketSort.Relevance, page: DEFAULT_PAGE }));
  };

  const applySelectedFilter = (filter: TicketFilterModel) => {
    setQuery({
      ...query,
      name: filter.name ?? undefined,
      assigneeIds: filter.assigneeIds?.length ? filter.assigneeIds : undefined,
      severities: filter.severities?.length ? filter.severities : undefined,
      components: filter.componentIds?.length ? filter.componentIds : undefined,
      componentTypeIds: filter.componentTypeIds?.length ? filter.componentTypeIds : undefined,
      states: filter.states?.length ? filter.states : undefined,
      authors: filter.authorIds?.length ? filter.authorIds : undefined,
      onlyFavorites: filter.onlyFavorites ?? undefined,
      createdAfter: filter.createdAfter ?? undefined,
      createdUntil: filter.createdUntil ?? undefined,
      modifiedAfter: filter.modifiedAfter ?? undefined,
      modifiedUntil: filter.modifiedUntil ?? undefined,
      type: filter.type ?? undefined,
      title: filter.title ?? undefined,
    });
  };

  const resetTicketQuery = () => {
    setQuery({
      name: undefined,
      assigneeIds: undefined,
      severities: undefined,
      states: undefined,
      onlyFavorites: undefined,
      components: undefined,
      authors: undefined,
      createdAfter: undefined,
      createdUntil: undefined,
      modifiedAfter: undefined,
      modifiedUntil: undefined,
      componentTypeIds: undefined,
      type: undefined,
      title: undefined,
    });
  };

  function handleUnselectFilter() {
    setQuery({ ...query, name: undefined });
  }

  const CreateTicketButton = useCallback(
    () => <NavigateButton to="create" text={t('createNewTicket')} size="is-small" icon="add" />,
    [t],
  );

  return (
    <div className="all-tickets has-pagination">
      <MultiSelectContainer context={TicketMultiSelectContext}>
        <AosActionBar
          left={
            <DebouncedAosSearchBar
              value={query.query ?? ''}
              isLoading={isLoading}
              onChangeDebounced={(newValue) => handleFilterChange('query', newValue)}
              placeholder={t('ticketSearchPlaceholder')}
              data-role="ticket-search-input"
              size="is-small"
              label={tCommons('search')}
            />
          }
          right={
            <Field>
              <Label size="is-small">{tCommons('moreActions')}</Label>
              <Controls>
                <AosExportButton
                  data-role="export-tickets-button"
                  href={pathForAllTicketsWithFilter([currentTenant], query as TicketQuery)}
                  size="is-small"
                  color="is-ghost"
                  isConfirm
                />
                <CreateTicketButton />
                <AosFilterMenuButton toggleMenu={toggleIsFilterHidden} />
              </Controls>
            </Field>
          }
        />
        <div className="container is-fullwidth is-fullheight overflow">
          <LoadingSpinner isLoading={isLoading}>
            <div className="tickets" data-role="all-tickets-overview">
              {ticketsPaged?.content?.length ? (
                ticketsPaged.content.map((ticket: Ticket, index: number) => (
                  <TicketingItem key={`ticketing-item-${ticket.id}`} index={index} ticket={ticket} showState />
                ))
              ) : (
                <HintWrapper tableResultOption={TableResultOption.NoData} />
              )}
              <footer className="pagination-footer">
                <Pagination
                  currentPage={query.page ?? 0}
                  totalPages={ticketsPaged?.totalPages ?? 0}
                  handleOnPageChange={onPageChange}
                  size="is-small"
                />
              </footer>
            </div>
          </LoadingSpinner>
        </div>

        <TicketFilter
          ticketFilter={query}
          handleFilterChange={handleFilterChange}
          resetTicketFilter={resetTicketQuery}
          applySelectedFilter={applySelectedFilter}
          isFilterHidden={isFilterHidden}
          hideFilter={toggleIsFilterHidden}
          handleUnselectFilter={handleUnselectFilter}
        />
      </MultiSelectContainer>
    </div>
  );
}
