import { RuleTrigger } from '@/models/operation/AutomationRuleModel';
import { useTranslationText } from '@/translation/TranslationHooks';
import TextInput from '../../../ui/text-input/TextInput';
import { GenericTriggerConfigurationProps } from './GenericTriggerConfigurationProps';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import SingleSelectDropdown from '@/ui/single-select-dropdown/SingleSelectDropdown';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { useState } from 'react';

interface TicketUnchangedTriggerConfiguration extends RuleTrigger {
  type: 'ticketUnchanged';
  minutesSinceLastChange: number;
}

enum TimeUnit {
  minutes = 1,
  hours = 60,
  days = 1440,
  weeks = 10080,
}

/**
 * The backend only saves the amount in minutes, thus we need to calculate the largest possible time unit for the given amount of minutes
 */
function calculateBiggestTimeUnitFromMinutes(minutes: number): { amount: number; unit: TimeUnit } {
  const unit = Object.values(TimeUnit)
    .filter((value) => minutes % Number(value) === 0)
    .at(-1) as TimeUnit; // We pick the last element because that's the largest possible time-unit (i.e. minutes: '60' -> both 'minutes' and 'hours' would be valid - but we want to pick 'hours').

  return { amount: minutes / unit, unit: unit };
}

export default function TicketUnchangedTrigger({
  trigger,
  handleConfigurationUpdate,
  onClear,
}: GenericTriggerConfigurationProps<TicketUnchangedTriggerConfiguration>) {
  const { t } = useTranslationText('tickets');
  const { t: tAutomations } = useTranslationText('automations');
  const [currentAmount, setCurrentAmount] = useState<number>(0);
  const [currentTimeUnit, setCurrentTimeUnit] = useState<TimeUnit>(TimeUnit.hours);

  const timeUnits = [
    {
      value: TimeUnit.minutes,
      label: tAutomations('timeUnitMinutes'),
    },
    {
      value: TimeUnit.hours,
      label: tAutomations('timeUnitHours'),
    },
    {
      value: TimeUnit.days,
      label: tAutomations('timeUnitDays'),
    },
    {
      value: TimeUnit.weeks,
      label: tAutomations('timeUnitWeeks'),
    },
  ];

  const handleAmountChange = (amount: string) => {
    const numberValue = Number(amount);
    if (Number.isNaN(numberValue)) return;

    if (!isNaN(numberValue) && Number.isInteger(numberValue) && numberValue > 0) {
      setCurrentAmount(numberValue);
      update(numberValue, currentTimeUnit);
    } else {
      setCurrentAmount(0);
      onClear();
    }
  };

  const handleUnitChange = (unit: any) => {
    setCurrentTimeUnit(unit.value as TimeUnit);
    if (currentAmount && currentAmount > 0) {
      update(currentAmount, unit.value);
    }
  };

  const update = (amount: number, unit: TimeUnit) => {
    handleConfigurationUpdate({
      type: 'ticketUnchanged',
      minutesSinceLastChange: amount * unit,
    });
  };

  if (trigger && !currentAmount) {
    const previousData = calculateBiggestTimeUnitFromMinutes(trigger.minutesSinceLastChange);
    setCurrentAmount(previousData.amount);
    setCurrentTimeUnit(previousData.unit);
  }

  return (
    <FormFieldWrapper
      isFullWidth={false}
      label={t('timeSinceLastChangeLabel', {
        amount: currentAmount === 0 ? 'x' : `${currentAmount}`,
        unit: (timeUnits.find((elem) => elem.value == currentTimeUnit) ?? timeUnits[1]).label,
      })}
      controls={[
        {
          component: (
            <TextInput
              key="ticket-unchanged-trigger-text-input"
              onValueChange={handleAmountChange}
              value={currentAmount && currentAmount > 0 ? currentAmount : ''}
            />
          ),
          isExpanded: true,
        },
        {
          component: (
            <SingleSelectDropdown
              defaultValue={currentTimeUnit ? timeUnits.find((elem) => elem.value == currentTimeUnit) : timeUnits[1]}
              key="ticket-unchanged-trigger-select"
              isAlternative
              options={timeUnits}
              requiredPermission={UserResourcePermissions.Rule.CreateOrUpdate}
              onChange={handleUnitChange}
            />
          ),
          isExpanded: false,
        },
      ]}
    />
  );
}
