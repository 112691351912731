import './CurrentTickets.scss';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useCallback } from 'react';
import { Controls, Field, Label } from '@aos/styleguide-react';
import { TicketQuery, TicketQueryParamConfigMap, TicketSort } from '@/models/operation/TicketQuery';
import { useTranslationText } from '@/translation/TranslationHooks';
import { pathForAllTicketsWithFilter, useGetStates } from '@/react-query/TicketingSystemApi';
import { CollapsibleElement } from './CollapsibleElement';
import TicketMultiSelectContext from '../TicketMultiSelectContext';
import MultiSelectContainer from '../../ui/multiselect/multiselect-container/MultiSelectContainer';
import { TicketState } from '@/models/operation/TicketStatesModel';
import TicketFilter from '../filter/TicketFilter';
import { TicketFilterModel } from '@/models/operation/TicketFilterModel';
import useToggle from '../../custom-hooks/useToggle';
import AosActionBar from '../../ui/action-bar/AosActionBar';
import { DebouncedAosSearchBar } from '@/ui/filter/search-bar/AosSearchBar';
import AosFilterMenuButton from '../../ui/filter/AosFilterMenuButton';
import AosExportButton from '../export-button/AosExportButton';
import NavigateButton from '../../generic-components/navigate-button/NavigateButton';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';

const DEFAULT_COLLAPSIBLE_PAGE_SIZE = 10;

export default function CurrentTickets() {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');
  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);
  const [query, setQuery] = useQueryParams({
    ...TicketQueryParamConfigMap,
    sort: withDefault(StringParam, TicketSort.CreatedAtDesc),
    size: withDefault(NumberParam, DEFAULT_COLLAPSIBLE_PAGE_SIZE),
  });
  const { currentTenant } = useCurrentTenant();

  const { data: states, isLoading: isStatesLoading, error: getStateFetchError } = useGetStates();

  const handleFilterChange = (name: keyof TicketQuery, value: any) => {
    setQuery({
      ...query,
      name: undefined,
      [name]: value,
    });
  };

  const resetTicketQuery = () => {
    setQuery({
      name: undefined,
      assigneeIds: undefined,
      severities: undefined,
      states: undefined,
      onlyFavorites: undefined,
      components: undefined,
      authors: undefined,
      createdAfter: undefined,
      createdUntil: undefined,
      modifiedAfter: undefined,
      modifiedUntil: undefined,
      componentTypeIds: undefined,
      type: undefined,
      title: undefined,
    });
  };

  const applySelectedFilter = (filter: TicketFilterModel) => {
    setQuery({
      ...query,
      name: filter.name ?? undefined,
      assigneeIds: filter.assigneeIds?.length ? filter.assigneeIds : undefined,
      severities: filter.severities?.length ? filter.severities : undefined,
      components: filter.componentIds?.length ? filter.componentIds : undefined,
      componentTypeIds: filter.componentTypeIds?.length ? filter.componentTypeIds : undefined,
      states: filter.states?.length ? filter.states : undefined,
      authors: filter.authorIds?.length ? filter.authorIds : undefined,
      onlyFavorites: filter.onlyFavorites ?? undefined,
      createdAfter: filter.createdAfter ?? undefined,
      createdUntil: filter.createdUntil ?? undefined,
      modifiedAfter: filter.modifiedAfter ?? undefined,
      modifiedUntil: filter.modifiedUntil ?? undefined,
      type: filter.type ?? undefined,
      title: filter.title ?? undefined,
    });
  };

  const handlePageChange = (state: TicketState, page: number) => {
    setQuery((prevState) => ({
      pagePerState: {
        ...prevState.pagePerState,
        [state.key]: page,
      },
    }));
  };

  function handleUnselectFilter() {
    setQuery({ ...query, name: undefined });
  }

  const collapsibleElements = states
    ?.filter((state) => state.key !== 'ARCHIVED')
    .map((state) => {
      const statesForMenu = states.filter((st) => st.key !== state.key);

      return (
        <CollapsibleElement
          key={`collapsible-${state.key}`}
          state={state}
          query={query}
          statesForMenu={statesForMenu}
          getStateFetchError={getStateFetchError}
          isStatesLoading={isStatesLoading}
          handlePageChange={handlePageChange}
          page={query?.pagePerState?.[state.key]}
        />
      );
    });

  const CreateTicketButton = useCallback(
    () => <NavigateButton to="create" text={t('createNewTicket')} size="is-small" icon="add" />,
    [t],
  );

  return (
    <div className="flex-container">
      <AosActionBar
        left={
          <DebouncedAosSearchBar
            value={query.query ?? ''}
            isLoading={isStatesLoading}
            onChangeDebounced={(newValue) => handleFilterChange('query', newValue)}
            placeholder={t('ticketSearchPlaceholder')}
            data-role="ticket-search-input"
            size="is-small"
            label={tCommons('search')}
          />
        }
        right={
          <Field>
            <Label size="is-small">{tCommons('moreActions')}</Label>
            <Controls>
              <AosExportButton
                data-role="export-tickets-button"
                href={pathForAllTicketsWithFilter([currentTenant], query as TicketQuery)}
                size="is-small"
                color="is-ghost"
                isConfirm
              />
              <CreateTicketButton />
              <AosFilterMenuButton toggleMenu={toggleIsFilterHidden} />
            </Controls>
          </Field>
        }
      />
      <div className="overflow">
        <div className="ticketing-system" data-role="tickets-overview">
          <MultiSelectContainer context={TicketMultiSelectContext}>
            {collapsibleElements}
            <TicketFilter
              ticketFilter={query}
              handleFilterChange={handleFilterChange}
              resetTicketFilter={resetTicketQuery}
              applySelectedFilter={applySelectedFilter}
              isFilterHidden={isFilterHidden}
              hideFilter={toggleIsFilterHidden}
              handleUnselectFilter={handleUnselectFilter}
              hasStateFilter={false}
            />
          </MultiSelectContainer>
        </div>
      </div>
    </div>
  );
}
