import { useCallback, useState } from 'react';
import { useTranslationText } from '@/translation/TranslationHooks';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { MailTemplate, RuleAction, RuleTrigger, UsersMailTemplate } from '@/models/operation/AutomationRuleModel';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import './SendEmailMessageAction.scss';
import TextArea from '../../../ui/text-area/TextArea';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import { useGetMailTemplate } from '@/react-query/AutomationApi';
import { MutationKey, MutationPath, PostMutationVariables, usePostMutation } from '@/react-query/MutationQueries';

export interface SendCustomMailConfiguration extends RuleAction {
  type: 'sendCustomMailMessage';
  templateName: string;
  body: string;
}

interface SendEmailMessageActionProps extends GenericActionConfigurationProps<SendCustomMailConfiguration> {
  selectedTrigger: RuleTrigger;
}

function delayExecution<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout | undefined;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): ReturnType<T> {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
    return undefined as ReturnType<T>;
  } as T;
}

export default function SendCustomMailMessageAction({
  configuration,
  handleConfigurationUpdate,
  selectedTrigger,
}: SendEmailMessageActionProps) {
  const { t } = useTranslationText('automations');
  const [customMailMessage, setCustomMailMessage] = useState(configuration?.body ?? '');
  const [renderedMailMessage, setRenderedMailMessage] = useState('');

  const { mutate: requestServersideRendering } = usePostMutation<UsersMailTemplate, MailTemplate>(
    MutationKey.MailTemplateServersideRendering,
    {
      onSuccess: (renderedMailMessage) => {
        setRenderedMailMessage(renderedMailMessage.body);
        handleConfigurationUpdate({
          type: 'sendCustomMailMessage',
          templateName: selectedTrigger.type,
          body: customMailMessage,
        });
      },
      onError: () => {
        setRenderedMailMessage(t('invalidTemplateError'));
      },
    },
  );

  const renderOnServerDelayed = useCallback(
    (templateToRender: PostMutationVariables<UsersMailTemplate>) =>
      delayExecution(() => requestServersideRendering(templateToRender), 3000)(),
    [requestServersideRendering],
  );

  const { data: mailTemplate } = useGetMailTemplate(selectedTrigger.type, {
    enabled: !!selectedTrigger.type,
  });

  return (
    <div className="send-email-message">
      <FormFieldWrapper label={t('sendToMeMyselfAndI_label')}>
        <SwitchInput id="sendToMyself" checked disabled />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailSubject_label')}>
        <div className="color-unset" dangerouslySetInnerHTML={{ __html: mailTemplate?.subject ?? '' }} />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailBody_label')}>
        <TextArea
          value={customMailMessage}
          onValueChange={(newTemplateText: string) => {
            setCustomMailMessage(newTemplateText);
            renderOnServerDelayed({
              body: { type: selectedTrigger.type, content: newTemplateText },
              path: MutationPath.RenderMailTemplateServerside,
            });
          }}
        />
      </FormFieldWrapper>
      <div dangerouslySetInnerHTML={{ __html: renderedMailMessage }} />
    </div>
  );
}
