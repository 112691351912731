import { StrapiFile } from './StrapiFile';

const MimeType = {
  Image: 'image',
} as const;

export function isImage(file: StrapiFile) {
  // TODO ADB-2224
  // mime ist kein Fehler, sondern ein quick and dirty fix. Das file Object hat manchmal irgendwie mime statt mimetype als attribut
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return file?.mimeType?.startsWith(MimeType.Image) || file?.mime?.startsWith(MimeType.Image);
}
