import { UserOverviewConfig } from '@/components/component-table/UserOverviewConfig';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { MonitoringApiPrefix } from './MonitoringApi';

export function useGetUserOverviewConfig(options?: QueryOptions<UserOverviewConfig>) {
  return useGetQuery<UserOverviewConfig>(
    [ServerStateKeysEnum.UserOverviewConfig],
    `${MonitoringApiPrefix}/user-config/overview`,
    options,
  );
}
