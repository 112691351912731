import { Field, Label, Table, Tag } from '@aos/styleguide-react';
import dayjs from 'dayjs';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { useGetAdvancedRules } from '@/react-query/AutomationApi';
import UserRoleCheck from '@/auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import LoadingSpinner from '@/ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '@/translation/TranslationHooks';
import DateFormats from '@/models/date-formats/DateFormats';
import LinkTableCell from '@/ui/link-table-cell/LinkTableCell';
import { AdvancedRule } from '@/automation/advanced-rules/AdvancedRuleModels';
import { AdvancedRuleQueryParamConfigMap } from '@/models/operation/AdvancedRuleQuery';
import Pagination from '@/ui/pagination/Pagination';
import ActionBar from '@/ui/action-bar/ActionBar';
import NavigateButton from '@/generic-components/navigate-button/NavigateButton';
import NavigationRoutes from '@/routing/NavigationRoutes';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';
import classNames from 'classnames';
import { useGetUser } from '@/react-query/TicketingSystemApi';

interface AdvancedRuleRowProps {
  advancedRule: AdvancedRule;
}

function AdvancedRuleRow({ advancedRule }: AdvancedRuleRowProps) {
  const { t } = useTranslationText('automations');
  const { data: createdBy, isError } = useGetUser(advancedRule.createdBy);

  return (
    <Table.Row>
      <LinkTableCell to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}>{advancedRule.name}</LinkTableCell>
      <LinkTableCell
        to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}
        dataRole="rule-creator-cell"
        className={classNames('created-by', { 'created-by-error': isError })}
      >
        {!isError ? `${createdBy?.firstName || ''} ${createdBy?.lastName || ''}` : '?'}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}>
        <Tag color={advancedRule.active ? 'is-success' : 'is-secondary'} size="is-normal">
          {advancedRule.active ? t('active') : t('inactive')}
        </Tag>
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}>
        {dayjs(advancedRule.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}>
        {dayjs(advancedRule.lastModified).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.AdvancedRuleEdit(advancedRule.id)}>
        {advancedRule.id.slice(-12)}
      </LinkTableCell>
    </Table.Row>
  );
}

function AdvancedRules() {
  const DEFAULT_PAGE_SIZE = 20;

  const { t } = useTranslationText('automations');
  const { t: tCommons } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');

  const [query, setQuery] = useQueryParams({
    ...AdvancedRuleQueryParamConfigMap,
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });
  const { data: advancedRules, isLoading, isError } = useGetAdvancedRules(query);

  function setPageTo(newPage: number) {
    setQuery({ ...query, page: newPage });
  }

  return (
    <div className="flex-container">
      <ActionBar
        right={
          <Field>
            <Label size="is-small">{tCommons('moreActions')}</Label>
            <NavigateButton
              to="create"
              text={t('createNewSystemRule')}
              requiredPermission={UserResourcePermissions.AdvancedRule.Create}
              size="is-small"
              icon="add"
              isConfirm
            />
          </Field>
        }
      />
      <UserRoleCheck requiredPermission={UserResourcePermissions.AdvancedRule.Read}>
        <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_systemRules') : undefined}>
          <div className="overflow" style={{ flex: 1 }}>
            <TableHintWrapper
              table={
                <>
                  <Table>
                    <Table.Header className="sticky-header">
                      <Table.Row>
                        <Table.HeaderCell>{t('ruleName')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('createdBy')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ruleStatus')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('createdAt')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('lastModified')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ruleId')}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    {!!advancedRules?.content.length && (
                      <Table.Body>
                        {advancedRules.content.map((advancedRule) => (
                          <AdvancedRuleRow key={advancedRule.id} advancedRule={advancedRule} />
                        ))}
                      </Table.Body>
                    )}
                  </Table>

                  {advancedRules && advancedRules?.content.length > 0 && (
                    <footer className="pagination-footer">
                      <Pagination
                        currentPage={advancedRules?.pageable.pageNumber ?? 0}
                        totalPages={advancedRules?.totalPages ?? 0}
                        handleOnPageChange={setPageTo}
                      />
                    </footer>
                  )}
                </>
              }
              tableResultOption={calculateTableState(advancedRules?.content ?? [], isError)}
            />
          </div>
        </LoadingSpinner>
      </UserRoleCheck>
    </div>
  );
}

export default AdvancedRules;
