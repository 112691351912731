import { Table as AosTable } from '@aos/styleguide-react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import useToggle from '../../custom-hooks/useToggle';
import {
  MaintenanceQuery,
  maintenanceQueryParamConfigMap,
  MaintenanceSort,
} from '@/models/maintenance/MaintenanceQuery';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import useGetAllUsers from '../../react-query/GetUsers';
import { useGetAllMaintenanceRequests } from '@/react-query/MaintenanceApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import FilterMenuButton from '../../ui/filter/FilterMenuButton';
import { DebouncedSearchBar } from '@/ui/filter/search-bar/SearchBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Pagination from '../../ui/pagination/Pagination';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { getNextSortState, SortState } from '@/ui/table-sort/TableSort';
import AllMaintenanceFilter from './all-maintenance-filter/AllMaintenanceFilter';
import MaintenanceOverviewRow from './MaintenanceOverviewRow';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

export default function MaintenanceOverview() {
  const { t } = useTranslationText('maintenances');
  const { t: tError } = useTranslationText('errorTexts');

  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);

  const [query, setQuery] = useQueryParams({
    ...maintenanceQueryParamConfigMap,
    sort: withDefault(StringParam, MaintenanceSort.CreatedAtAsc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });

  const handleFilterChange = (name: keyof MaintenanceQuery, value: string | string[] | boolean | undefined) => {
    setQuery((prev) => ({ ...prev, [name]: value, page: DEFAULT_PAGE }));
  };

  const resetMaintenanceFilter = () => {
    setQuery((q) => ({
      ...q,
      title: undefined,
      status: undefined,
      maintenanceBetweenAfter: undefined,
      maintenanceBetweenUntil: undefined,
      components: undefined,
    }));
  };

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort?.startsWith(property) ?? false;
    const sortState = getNextSortState(currentSortState, resetSortState);
    const sortQuery = sortState === SortState.None ? undefined : `${property}:${sortState}`;
    setQuery({ ...query, sort: sortQuery });
  }

  const { data: maintenanceRequests, isLoading, isError } = useGetAllMaintenanceRequests({}, query as MaintenanceQuery);

  const { data: users, isError: isGetUserError } = useGetAllUsers(
    maintenanceRequests?.content?.map((maintenance) => maintenance.author?.id ?? '').filter((value) => value !== ''),
  );

  const tableColumns: TableColumn[] = [
    { property: 'actionNumber', translationKey: 'actionNumber', disableSort: false },
    { property: 'title', translationKey: 'title', disableSort: false },
    { property: 'status', translationKey: 'status', disableSort: false },
    { property: 'author', translationKey: 'author', disableSort: true },
    { property: 'components', translationKey: 'components', disableSort: true },
    { property: 'startOfAction', translationKey: 'startOfAction', disableSort: false },
    { property: 'endOfAction', translationKey: 'endOfAction', disableSort: false },
  ];

  return (
    <div className="flex-container">
      <ActionBar
        center={
          <DebouncedSearchBar
            value={query.title ?? ''}
            isLoading={isLoading}
            onChangeDebounced={(newValue) => handleFilterChange('title', newValue)}
            placeholder={t('maintenanceSearchPlaceholder')}
          />
        }
        right={
          <FilterMenuButton
            query={query}
            toggleMenu={toggleIsFilterHidden}
            countWhen={(propertyName) => propertyName !== 'maintenanceBetweenUntil'}
          />
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_maintenance_requests') : undefined}>
        <div className="overflow" style={{ flex: 1 }}>
          <TableHintWrapper
            table={
              <>
                <AosTable>
                  <TableHeader
                    columns={tableColumns}
                    translationSection="maintenances"
                    currentSort={query.sort || undefined}
                    onSortChange={onSortChange}
                  />
                  <AosTable.Body>
                    {maintenanceRequests?.content.map((maintenance) => (
                      <MaintenanceOverviewRow
                        key={maintenance.id}
                        maintenance={maintenance}
                        users={users}
                        isGetUserError={isGetUserError}
                      />
                    ))}
                  </AosTable.Body>
                </AosTable>
                {maintenanceRequests && maintenanceRequests.content.length > 0 && (
                  <footer className="pagination-footer">
                    <Pagination
                      currentPage={query.page ?? 0}
                      totalPages={maintenanceRequests?.totalPages ?? 0}
                      handleOnPageChange={onPageChange}
                      size="is-small"
                    />
                  </footer>
                )}
              </>
            }
            tableResultOption={calculateTableState(maintenanceRequests?.content ?? [], isError)}
          />
        </div>
      </LoadingSpinner>
      <AllMaintenanceFilter
        maintenanceFilter={query}
        handleFilterChange={handleFilterChange}
        resetMaintenanceFilter={resetMaintenanceFilter}
        isFilterHidden={isFilterHidden}
        hideFilter={toggleIsFilterHidden}
      />
    </div>
  );
}
