import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import SearchDropdown, { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { UserModel } from '@/models/operation/UserModel';
import { mapUsersToOptions, mapUserToOption } from '@/models/operation/TicketFunctions';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import useGetAllUsers from '../../../react-query/GetUsers';
import { useTranslationText } from '@/translation/TranslationHooks';
import { useGetUser } from '@/react-query/TicketingSystemApi';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { RuleAction } from '@/models/operation/AutomationRuleModel';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

export interface ChangeTicketAssigneeConfiguration extends RuleAction {
  readonly type: 'changeTicketAssignee';
  readonly assigneeId: string;
}

export default function ChangeTicketAssigneeAction({
  configuration,
  handleConfigurationUpdate,
}: GenericActionConfigurationProps<ChangeTicketAssigneeConfiguration>) {
  const [assigneeId, setAssigneeId] = useState<string | null>();
  const { data: users, isLoading: areUsersLoading, isError: isUsersError, setUserQuery } = useGetAllUsers();
  const { t } = useTranslationText('tickets');
  const { data: user } = useGetUser(assigneeId!, { enabled: !!assigneeId });

  useEffect(() => {
    setAssigneeId(configuration?.assigneeId);
  }, [configuration]);

  const handleChange = (option: SingleValue<ReactSelectOption>) => {
    setAssigneeId(option?.value);
    if (option?.value) {
      handleConfigurationUpdate({
        type: 'changeTicketAssignee',
        assigneeId: option?.value,
      } as ChangeTicketAssigneeConfiguration);
    }
  };

  return (
    <FormFieldWrapper label={t('assignedUser')}>
      <SearchDropdown<UserModel | null>
        isError={isUsersError}
        onChange={handleChange}
        onInputChange={setUserQuery}
        isLoading={areUsersLoading}
        noOptionsMessage={t('noSuggestionsUsers')}
        options={mapUsersToOptions(users?.content)}
        isLoadingMessage={t('usersAreLoading')}
        mapValueToSelectOption={mapUserToOption}
        value={user}
        placeholder={t('assignUser')}
        requiredPermission={UserResourcePermissions.Rule.CreateOrUpdate}
        isClearable
      />
    </FormFieldWrapper>
  );
}
